<template>
    <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" class="checkout-setting-form">
        <div class="content_area">
            <div class="content_wpr">
                <div class="section_content w-100">
                    <div class="section_header">
                        <h2>Dunning</h2>
                        <div class="tutorial_btn" @click="tutorialVideo"><label>Tutorials</label><img src="@/assets/images/play.svg"></div>
                        <!-- <button type="button" class="video_btn setting-video-btn" @click="tutorialVideo"><i class="fas fa-play"></i></button> -->
                    </div>
                    <div class="mt-1 p-0 border-0">
                        <div class="setting_wpr">
                            <ul class="mail_types">
                                <li :class="{ 'show': tab == 1 }">
                                    <h4 @click="tab = (tab == 1 ? 0 : 1)">Email 1: Friendly Reminder<i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <p class="note mb-4">Sent right after any subscription payment failure<br /><br />Customize the default dunning email sent to the customer when any subscription payment fails. This can be overridden by customizing the individual dunning steps below.</p>
                                            <email-component v-if="isMounted" v-model="defaultDunning" :errors="errors" :handle-default-email="handleDefaultDunningDefaultEmail" />
                                        </div>
                                    </div>
                                </li>
                                <li :class="{ 'show': tab == 2 }">
                                    <h4  @click="tab = (tab == 2 ? 0 : 2)">Email 2: Gentle Nudge<i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <p class="note mb-4">Sent immediately after 2nd payment failure</p>
                                            <email-component v-if="isMounted" v-model="dunningStepOne" :errors="errors" :handle-default-email="handleDunningStepOneDefaultEmail" />
                                        </div>
                                    </div>
                                </li>
                                <li :class="{ 'show': tab == 3 }">
                                    <h4  @click="tab = (tab == 3 ? 0 : 3)">Email 3: Urgent Action Needed<i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <p class="note mb-4">Sent immediately after 3rd payment failure</p>
                                            <email-component v-if="isMounted" v-model="dunningStepTwo" :errors="errors" :handle-default-email="handleDunningStepTwoDefaultEmail" />
                                        </div>
                                    </div>
                                </li>
                                <li :class="{ 'show': tab == 4 }">
                                    <h4  @click="tab = (tab == 4 ? 0 : 4)">Email 4: Final Notice<i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <p class="note mb-4">Sent immediately after 4th payment failure</p>
                                            <email-component v-if="isMounted" v-model="dunningStepThree" :errors="errors" :handle-default-email="handleDunningStepThreeDefaultEmail" />
                                        </div>
                                    </div>
                                </li>
                                <!-- <li :class="{ 'show': tab == 5 }">
                                    <h4  @click="tab = (tab == 5 ? 0 : 5)">Dunning Step 4<i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <p class="note mb-4">Sent immediately after 4th payment failure</p>
                                            <email-component v-if="isMounted" v-model="dunningStepFour" :errors="errors" :handle-default-email="handleSubscriptionRefundedDefaultEmail" />
                                        </div>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="action_wpr">
            <button type="button" class="btn cancel_btn" @click="$parent.$parent.closeModal()">Cancel</button>
            <button :disabled="loader" type="submit" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
        </div>
    </Form>
</template>

<script>
    import { Form } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Dunning Email Setting',

        data () {
            return {
                tab: 1,
                defaultDunning: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                dunningStepOne: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                dunningStepTwo: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                dunningStepThree: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                dunningStepFour: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                isMounted: false,
            }
        },

        props: {
            updateDunningEmail: {
                type: Function,
                default: () => {}
            },
            tutorialVideo : Function,
        },

        components: {
            Form,
        },

        watch: {
            dunningEmailSettings (setting) {
                const vm = this;

                vm.resetForm();
            },

            tab (tab) {
                const vm = this;

                if (tab == 1) {
                    vm.updateDunningEmail(vm.defaultDunning);
                } else if (tab == 2) {
                    vm.updateDunningEmail(vm.dunningStepOne);
                } else if (tab == 3) {
                    vm.updateDunningEmail(vm.dunningStepTwo);
                } else if (tab == 4) {
                    vm.updateDunningEmail(vm.dunningStepThree);
                } else if (tab == 5) {
                    vm.updateDunningEmail(vm.dunningStepFour);
                }
            },

            defaultDunning: {
                handler (defaultDunning) {
                    const vm = this;

                    if (vm.tab == 1) {
                        vm.updateDunningEmail(defaultDunning);
                    }
                },
                deep: true,
            },

            dunningStepOne: {
                handler (dunningStepOne) {
                    const vm = this;

                    if (vm.tab == 2) {
                        vm.updateDunningEmail(dunningStepOne);
                    }
                },
                deep: true,
            },

            dunningStepTwo: {
                handler (dunningStepTwo) {
                    const vm = this;

                    if (vm.tab == 3) {
                        vm.updateDunningEmail(dunningStepTwo);
                    }
                },
                deep: true,
            },

            dunningStepThree: {
                handler (dunningStepThree) {
                    const vm = this;

                    if (vm.tab == 4) {
                        vm.updateDunningEmail(dunningStepThree);
                    }
                },
                deep: true,
            },

            dunningStepFour: {
                handler (dunningStepFour) {
                    const vm = this;

                    if (vm.tab == 5) {
                        vm.updateDunningEmail(dunningStepFour);
                    }
                },
                deep: true,
            },
        },

        computed: mapState({
            dunningEmailSettings: state => state.checkoutModule.dunningEmailSettings,
            emailSettingLoader: state => state.checkoutModule.emailSettingLoader,
            loader: state => state.checkoutModule.updateEmailSettingLoader,
        }),

        mounted () {
            const vm = this;

            if (!Object.keys(vm.dunningEmailSettings).length) {
                vm.getDunningEmailSettings({type: 3});
            }

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getDunningEmailSettings: 'checkoutModule/getEmailSettings',
                updateEmailSettings: 'checkoutModule/updateEmailSettings'
            }),

            resetForm () {
                const vm = this;

                if (vm.dunningEmailSettings && vm.dunningEmailSettings.length) {
                    const emailSettings = JSON.parse(JSON.stringify(vm.dunningEmailSettings));

                    emailSettings.forEach(({ step }, index) => {
                        if (step == 1) {
                            vm.defaultDunning = emailSettings[index];
                            vm.defaultDunning.defaultHandler = true;
                        } else if (step == 2) {
                            vm.dunningStepOne = emailSettings[index];
                            vm.dunningStepOne.defaultHandler = true;
                        } else if (step == 3) {
                            vm.dunningStepTwo = emailSettings[index];
                            vm.dunningStepTwo.defaultHandler = true;
                        } else if (step == 4) {
                            vm.dunningStepThree = emailSettings[index];
                            vm.dunningStepThree.defaultHandler = true;
                        } else if (step == 5) {
                            vm.dunningStepFour = emailSettings[index];
                            vm.dunningStepFour.defaultHandler = true;
                        }
                    });

                    vm.isMounted = true;
                }
            },

            handleSubmit () {
                const vm = this;

                const form = [ vm.defaultDunning, vm.dunningStepOne, vm.dunningStepTwo, vm.dunningStepThree, vm.dunningStepFour ];

                vm.updateEmailSettings(form).then((result) => {
                    if (result) {
                        vm.getDunningEmailSettings({type: 3});
                    }
                });
            },

            handleDefaultDunningDefaultEmail () {
                const vm = this;

                vm.defaultDunning.subject = 'Hey {{first_name}}, there\'s a small issue with your {{product_name}} subscription';
                vm.defaultDunning.email   =  `<p>Hi {{first_name}},</p>
                                              <p>I hope you\'re enjoying your {{product_name}} subscription! We noticed there was a hiccup with the billing for your subscription ID {{subscription_id}}. It looks like your card on file failed to process the payment.</p>
                                              <p>No worries, though. It\'s easy to fix! Just click the link below to securely update your card information through Stripe:</p>
                                              <p>{{update_card_button}}</p>
                                              <p>If you have any questions or need assistance, feel free to contact our support team at {{support_email}}.</p>
                                              <p>Let\'s get this sorted out so you can continue enjoying your {{product_name}} subscription!</p>
                                              <p><b>Best regards,</b></p>
                                              <p>{{company_owner_first_name}},</p>
                                              <p>{{company_name}}</p>
                                              <p><br />Please do not reply to this email it is not a monitored address.</p>`;
            },

            handleDunningStepOneDefaultEmail () {
                const vm = this;

                vm.dunningStepOne.subject = '{{first_name}}, your {{product_name}} subscription needs your attention';
                vm.dunningStepOne.email   = `<p>Hi {{first_name}},</p>
                                            <p>Just a quick reminder that there\'s still an issue with your {{product_name}} subscription (ID: {{subscription_id}}). Your card on file didn\'t go through for the last billing cycle.</p>
                                            <p>To avoid any interruptions to your subscription, please update your card information as soon as possible:</p>
                                            <p>{{update_card_button}}</p>
                                            <p>If you need any help, don\'t hesitate to contact our support team at {{support_email}}.</p>
                                            <p>Thanks for taking care of this, {{first_name}}!</p>
                                            <p><b>Best regards,</b></p>
                                            <p>{{company_owner_first_name}},</p>
                                            <p>{{company_name}}</p>
                                            <p><br />Please do not reply to this email it is not a monitored address.</p>`;
            },

            handleDunningStepTwoDefaultEmail () {
                const vm = this;

                vm.dunningStepTwo.subject = '{{first_name}}, urgent: your {{product_name}} subscription is at risk';
                vm.dunningStepTwo.email   = `<p>Hi {{first_name}},</p>
                                            <p>Unfortunately, we still haven\'t received an updated payment method for your {{product_name}} subscription (ID: {{subscription_id}}). Your access to the program is at risk of being suspended.</p>
                                            <p>Please take a moment to update your card information now to avoid any disruptions:</p>
                                            <p>{{update_card_button}}</p>
                                            <p>If you\'re experiencing any issues or have questions, reach out to our support team at {{support_email}}. We\'re here to help!</p>
                                            <p>Don\'t miss out on the benefits of your {{product_name}} subscription, {{first_name}}.</p>
                                            <p><b>Best regards,</b></p>
                                            <p>{{company_owner_first_name}},</p>
                                            <p>{{company_name}}</p>
                                            <p><br />Please do not reply to this email it is not a monitored address.</p>`;
            },

            handleDunningStepThreeDefaultEmail () {
                const vm = this;

                vm.dunningStepThree.subject = '{{first_name}}, last chance to save your {{product_name}} subscription!';
                vm.dunningStepThree.email   = `<p>Hi {{first_name}},</p>
                                              <p>This is your final notice. We still haven\'t received an updated payment method for your {{product_name}} subscription (ID: {{subscription_id}}). As a result, your account has been suspended and is scheduled for deletion.</p>
                                              <p>To reactivate your account and continue enjoying the benefits of your {{product_name}} subscription, please update your card information immediately:</p>
                                              <p>{{update_card_button}}</p>
                                              <p>If you have any questions or need assistance, contact our support team at {{support_email}} right away.</p>
                                              <p>Act quickly, {{first_name}}. This is your last chance to save your {{product_name}} subscription!</p>
                                              <p><b>Best regards,</b></p>
                                              <p>{{company_owner_first_name}},</p>
                                              <p>{{company_name}}</p>
                                              <p><br />Please do not reply to this email it is not a monitored address.</p>`;

            },

            handleSubscriptionRefundedDefaultEmail () {
                const vm = this;

                vm.dunningStepFour.subject = 'Account Suspended';
                vm.dunningStepFour.email   = `<p>Hi {{first_name}},</p>
                                              <p>We failed to bill your account for {{product_name}} 5 times and your account has been canceled and is scheduled for deletion.</p>
                                              <p>You will no longer be able to log in or access your program or your data.</p><p>To save your data please contact {{support_email}} immediately.</p>
                                              <p>Sincerely,</p>
                                              <p>{{company_name}}</p>`;
            },
        }
    }
</script>

<style scoped>
    .section_header .tutorial_btn{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .section_header .tutorial_btn label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        cursor: pointer;
        min-width: auto;
    }
    .section_header .tutorial_btn img {
        max-height: 18px;
        width: auto;
        margin-left: 5px;
    }
    .checkout-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .mail_types  {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .mail_types li  {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
        overflow: hidden;
    }

    .mail_types li h4  {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: move;
    }

    .mail_types li h4 i  {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        cursor: pointer;
        color: #7e7e7e;
        transition: all 0.3s ease-in-out;
    }

    .mail_types li.show h4 i  {
        transform: rotate(-180deg);
    }

    .mail_types li .accordion_area  {
        max-height: 0;
        padding: 0 30px;
        height: 100%;
        border-top: 1px solid #e9e9e9;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .mail_types li.show .accordion_area  {
        max-height: 1000vh;
        height: 100%;
        overflow-y: scroll;
    }

    .mail_types li.show .accordion_area::-webkit-scrollbar {
        display: none;
    }

    p.note {
        padding: 15px 20px;
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid rgba(47, 126, 237, 0.1);
        border-radius: 5px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #121525;
    }

    :deep(p.note p) {
        margin: 10px 0;
    }
    @media(max-width: 599px){
        .mail_types li h4  {
            font-size: 13px;
            line-height: 18px;
            padding: 15px 20px;
        }
        .mail_types li .accordion_area{
            padding: 0 15px;
        }
        p.note {
            padding: 10px 15px;
            font-size: 11px;
            line-height: 18px;
        }
    }
</style>
